import React from 'react'
import styled from 'styled-components'
import { useField } from 'formik'

import { palette } from '../../lib/cssHelpers'

export const Input = styled.input<{ dark?: boolean; disabled?: boolean }>`
  background-color: ${(p) => (p.dark ? '#171717' : '')}};
  border-radius: 0.25rem;
  border: ${(p) => (p.dark ? 0 : 2)}px solid ${(p) => (p.disabled ? palette.gray1 : palette.secondary)};
  color: ${(p) => (p.dark ? '#b9b9b9' : 'black')}};
  font-family: inherit;
  font-size: 0.95em;
  line-height: 1.4rem;
  max-width: 100%;
  height: 38px !important;
  outline: none;
  padding: 0.45rem 0.7rem;
  transition: 0.3s box-shadow;
  box-shadow: ${(p) => p.dark && '0px 0px 8px 5px rgb(50 50 50 / 50%)'};
  &:focus {
    box-shadow: ${(p) =>
      p.dark ? '0px 0px 8px 5px rgb(50 50 50 / 50%)' : '0 2px 2px 0 rgb(19 38 74 / 8%), 4px 4px 6px 0 rgb(19 38 74 / 24%)'}};
    border: ${(p) => (p.dark ? 0 : 2)} solid ${palette.secondary};
  }
`

export const Textarea = styled.textarea<{ dark?: boolean; disabled?: boolean }>`
  background-color: ${(p) => (p.dark ? '#171717' : '')}};
  border-radius: 0.25rem;
  border: ${(p) => (p.dark ? 0 : 2)}px solid ${palette.secondary};
  color: ${(p) => (p.dark ? '#b9b9b9' : 'black')}};
  font-family: inherit;
  font-size: 0.95em;
  line-height: 1.4rem;
  max-width: 100%;
  outline: none;
  padding: 0.45rem 0.7rem;
  transition: 0.3s box-shadow;
  box-shadow: ${(p) => p.dark && '0px 0px 8px 5px rgb(50 50 50 / 50%)'};
  &:focus {
    box-shadow: ${(p) =>
      p.dark ? '0px 0px 8px 5px rgb(50 50 50 / 50%)' : '0 2px 2px 0 rgb(19 38 74 / 8%), 4px 4px 6px 0 rgb(19 38 74 / 24%)'}};
    border: ${(p) => (p.dark ? 0 : 2)}px solid ${palette.secondary};
  }
`

// @ts-ignore
export const FormikInput = ({ label, ...props }) => {
  // @ts-ignore
  const [field, meta] = useField(props)

  return (
    <div className={props.containerClassName || ''}>
      <p className="text-s bold m-0">
        {label}
        {props.subLabel && <span className="regular d-block m-0">{props.subLabel}</span>}
      </p>

      <Input onClick={(e) => e.stopPropagation()} className="text-input data-hj-allow" {...field} {...props} />

      {meta.error ? <p className="error">{meta.error}</p> : null}
    </div>
  )
}
